import React, {useEffect, useState} from "react"
import {loadStripe} from '@stripe/stripe-js';
import Spinner from 'react-bootstrap/spinner'
import useFirebase from 'src/components/useFirebase'
import Layout from "src/components/layout"
import SEO from 'src/components/seo'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import SupportHelmet from 'src/components/SupportHelmet'
import { identify, trackPage, trackEvent} from 'src/utilities/analytics'
import { createCheckoutSession as createCheckoutSessionHTTPRequest } from 'src/utilities/http-requests'
import Curve1 from 'src/images/shapes/curve-1.svg'
import Blur3 from 'src/images/shapes/blur-3.svg'
import SoftGreenCheckmarkBadge from 'src/components/shared/badges/SoftGreenCheckmarkBadge'
import ArrowRight from 'src/images/icons/arrow-right.svg'

const pageName = 'Plans'
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

const CardLineItem = ({text}) => {
  const badgeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  return (
    <div class="d-flex">
      <SoftGreenCheckmarkBadge/>
      <p>
        {text}
      </p>
    </div>
    )
}

const Card = ({title, subtitle, lineItems, isEmphasized, buttonText, priceAmount, buttonOnClick, isButtonLoading, currencySymbol, priceAmountFollowText,}) => {
  const buttonArrowStyle = {
    width: 17,
    height: 17,
  }
  const buttonArrowFill = isEmphasized ? 'white' : '#335eea'
  const buttonClassNameModifier = isEmphasized ? 'btn-primary' : 'btn-primary-soft'
  const buttonClassName = `btn btn-block ${buttonClassNameModifier}`

  const spinnerVariant = isEmphasized ? 'light' : 'primary'
  const rightArrow = <ArrowRight fill={buttonArrowFill} style={buttonArrowStyle} class="fe fe-arrow-right ml-3"/>
  const loadingSpinner = <Spinner animation="border" variant={spinnerVariant}/>
  const buttonContent = isButtonLoading ? loadingSpinner : [buttonText, rightArrow]
  return (
    <div class="col-12 col-md-6">
      <div class="card shadow-lg mb-6 mb-md-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <span class="badge badge-pill badge-primary-soft">
              <span class="h6 text-uppercase">
                {title}
              </span>
            </span>
          </div>
          <div class="d-flex justify-content-center">
            <span class="h2 mb-0 mt-2">{currencySymbol}</span>
            <span class="price display-2 mb-0" data-annual="0" data-monthly="0">
              {priceAmount}
            </span>
            <span class="h2 mb-0 mt-2 ml-1">{priceAmountFollowText}</span>
          </div>
          <p class="text-center text-muted mb-5">
            {subtitle}
          </p>
          {
            lineItems.map((item) => <CardLineItem text={item.text} key={item.text}/>)
          }
          <button class={buttonClassName} onClick={buttonOnClick} disabled={isButtonLoading}>
            {buttonContent}
          </button>
        </div>
      </div>
    </div>
    )
}

const Question = ({question, answer}) => {
  return (
    <div>
      <h3>
        {question}
      </h3>
      <p class="text-gray-800 mb-6 mb-md-8">
        {answer}
      </p>
    </div>
    )
}

const FAQ = () => {
  const item1 = {
    question: 'Can I change my plan?',
    answer: 'Yes, you can upgrade, downgrade or cancel your plan at any time. Do it yourself in your account or contact us, we are here to help.',
  }
  const item2 = {
    question: 'Do I get full access with the trial?',
    answer: 'Yes, the trial has all the premium features, with unlimited usage. 100% Full Access.',
  }
   const item3 = {
    question: 'What about money back?',
    answer: 'Try NextLevelResume.co risk-free for 3 days. If you are not satisfied, you can cancel your service within 3 days of activation for a full refund.',
  }
  return (
    <section class="py-8 py-md-11 bg-white border-bottom">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-md-8 text-center">
            <span class="badge badge-pill badge-primary-desat-soft mb-3">
              <span class="h6 text-uppercase">FAQ</span>
            </span>
            <h2>
              Frequently asked questions.
            </h2>
            <p class="font-size-lg text-muted mb-7 mb-md-9">
              Let us help answer the most common questions you might have.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <Question {...item1}/>
            <Question {...item2}/>
          </div>
          <div class="col-12 col-md-6">
            <Question {...item3}/>
          </div>
        </div>
      </div>
    </section>
    )
}

const CardsSection = ({successURL, currentUser}) => {
  // determine which, if any, card buttons are showing loading spinner
  // value is either null (no buttons loading), 0, 1, 2 - referring to index left to right
  const [loadingButtonIndex, setLoadingButtonIndex] = useState(null)
  const baseItems = [
    {text: 'Unlimited PDF Downloads'},
    {text: 'Save Up to 10 Different Resumes'},
  ]
  const endItems = [
    {text: 'Cancel any time'},
    {text: '3-day money back guarantee'},
  ]
  const basicLineItems = [
    ...baseItems,
    {text: 'After 3 days, rolls over into $11.95/month'},
    ...endItems,
  ]
  const standardLineItems = [
    ...baseItems,
    {text: 'Save 33% by paying upfront for 3 months'},
    ...endItems,
  ]
  // this function relies on variables in the component scope
  // successURL, currentUser
  const createCheckoutSession = ({mode, line_items, subscription_data, showSpinnerForButtonIndex, revenue}) => {
    // send user to successURL from this page's url params
    // fallback to /resumes
    const successURLString = successURL || `${process.env.BASE_URL}/resumes`
    // add stripe_success=true param to successURL
    const successURLObject = new URL(successURLString)
    const searchParams = new URLSearchParams(successURLObject.search);
    searchParams.set('stripe_success', 'true')
    if (revenue) {
      searchParams.set('revenue', revenue)
    }
    successURLObject.search = searchParams
    const success_url = successURLObject.toString()
    console.log('success_url: ', success_url)
    trackEvent('lol', {success_url})
    // send user back to this page on cancel. fallback to /resumes
    const cancel_url = typeof window !== 'undefined' ? window.location.href : `${process.env.BASE_URL}/resumes`
    setLoadingButtonIndex(showSpinnerForButtonIndex)
    // make request, get back response json via promise
    const createCheckoutSessionPromise = createCheckoutSessionHTTPRequest({
      line_items,
      mode,
      success_url,
      cancel_url,
      subscription_data,
      userUID: currentUser.uid,
    })
    // once create_checkout_session request finishes
    // pass the sessionId from response to stripe
    // stripe client is given as a promise, so we wait for it to resolve
    // before adding it to promise chain
    Promise.all([createCheckoutSessionPromise, stripePromise])
    .then((results) => {
      const [sessionId, stripe] = results
      stripe.redirectToCheckout(sessionId)
      setLoadingButtonIndex(null)
    })
    .catch((error) => {
      console.error(error)
      setLoadingButtonIndex(null)
    })
  }

  const createSubscriptionWithTrial = () => {
    // 3 days free, then monthly subscription
    const subscriptionPriceId = process.env.SUBSCRIPTION_PRICE_ID
    const lineItems = [
      {
        price: subscriptionPriceId,
        quantity: 1
      }
    ]
    createCheckoutSession({
      mode: 'subscription',
      line_items: lineItems,
      subscription_data: { trial_period_days: 3 },
      showSpinnerForButtonIndex: 0,
      revenue: 12,
    })
    trackEvent('createSubscriptionWithTrial', {page: pageName})
  }

  const createThreeMonthPrePay = () => {
    // subscription billed in 3-month interval at 33% discount
    const lineItems = [
      {
        price: process.env.THREE_MONTH_PREPAY_PRICE_ID,
        quantity: 1
      },
    ]
    createCheckoutSession({
      mode: 'subscription',
      line_items: lineItems,
      showSpinnerForButtonIndex: 1,
      revenue: 24,
    })
    trackEvent('createThreeMonthPrePay', {page: pageName})
  }

  return (
    <section class="mt-n8 mt-md-n14">
      <div class="container">
        <div class="form-row">
          <Card
            title="3-day trial"
            subtitle="Try us out for free for 3 days."
            lineItems={basicLineItems}
            buttonText='Start Trial'
            isEmphasized={true}
            priceAmount='Free'
            currencySymbol=''
            buttonOnClick={createSubscriptionWithTrial}
            isButtonLoading={loadingButtonIndex == 0}
          />
          <Card
            title="3-month Plan"
            subtitle="Save 33%. Billed every 3 months."
            lineItems={standardLineItems}
            buttonText='Upgrade & Save'
            isEmphasized={false}
            priceAmount='7.98'
            currencySymbol='$'
            buttonOnClick={createThreeMonthPrePay}
            isButtonLoading={loadingButtonIndex == 1}
            priceAmountFollowText='/ month'
          />
        </div>
      </div>
    </section>
    )
}

// https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
const parseQuery = (queryString) => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    pairs.forEach((pair) => {
      const splitPair = pair.split('=');
      query[decodeURIComponent(splitPair[0])] = decodeURIComponent(splitPair[1] || '')
    })
    return query;
}

// accepts a url param successURL to redirect users to after they successfully check out
const Plans = ({location}) => {
  // https://www.gatsbyjs.com/docs/location-data-from-props/
  const urlParams = parseQuery(location.search)
  const { successURL } = urlParams

  const firebase = useFirebase();
  const [currentUser, setCurrentUser] = useState(null)
  // if no current user force login modal
  // but don't make login modal flicker before user is loaded
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      identify(user)
    });
    }, [firebase]);

  useEffect(() => {
    trackPage(pageName)
  }, [])

  return (
    <Layout
      shouldForceLoginModalOpenIfNoCurrentUser={true}
    >
      <SEO title={pageName}/>
      <AnalyticsHelmet/>
      <SupportHelmet/>
      <section class="pt-8 pt-md-11 pb-10 pb-md-15 bg-primary">
        <div class="shape shape-blur-3 svg-shim text-white">
          <Blur3/>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">
              <h1 class="display-2 text-white">
                The best way to build a resume.
              </h1>
              <p class="lead text-white-80 mb-6 mb-md-8">
                Get the job you know you deserve. Take your resume to the next level.
              </p>

            </div>
          </div>
        </div>
      </section>

      <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x svg-shim text-light">
          <Curve1/>
        </div>
      </div>

      <CardsSection {...{successURL, currentUser}}/>

      <FAQ/>
    </Layout>
  )
}

export default Plans
